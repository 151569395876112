var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"modal-cadastro",attrs:{"persistent":"","max-width":_vm.variables.widthModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',[_c('v-toolbar',{staticClass:"modal-cadastro-toolbar",attrs:{"flat":"","height":"40px"}},[_c('div',{staticClass:"abas",attrs:{"width":"100%"}},[_c('v-tabs',{attrs:{"background-color":"#FFF","height":"30px"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"abas-item",attrs:{"href":"#tab-1"},on:{"click":_vm.getCredits}},[_vm._v(" Creditos ")]),_c('v-tab',{staticClass:"abas-item",attrs:{"href":"#tab-2"},on:{"click":_vm.getUsuarios}},[_vm._v(" Usuários ")])],1)],1),_c('v-spacer'),_c('v-icon',{staticClass:"modal-cadastro-close",on:{"click":_vm.closeModal}},[_vm._v("close")])],1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:1,attrs:{"value":'tab-1'}},[_c('v-container',[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingSkeleton,"transition":'fade-transition',"height":"350px","type":"table"}},[_c('v-data-table',{staticClass:"table-extrato-creditos",attrs:{"headers":_vm.headers,"items":_vm.credits,"flat":"","dense":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.dtActivated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dtActivated))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]}}])})],1)],1)],1),_c('v-tab-item',{key:2,attrs:{"value":'tab-2'}},[_c('v-container',[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingSkeleton,"transition":'fade-transition',"height":"350px","type":"table"}},[_c('v-data-table',{staticClass:"table-extrato-creditos",attrs:{"headers":_vm.headersEntities,"items":_vm.entities,"flat":"","dense":""},scopedSlots:_vm._u([{key:"item.jsonData.cpf",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.jsonData.cpf))+" ")]}},{key:"item.jsonData.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.jsonData.phone))+" ")]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog persistent 
    v-model="showModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <div class="abas" width="100%">
            <v-tabs
              v-model="tab"
              background-color="#FFF"
              height="30px">
              <v-tab href="#tab-1" class="abas-item" @click="getCredits">
                Creditos
              </v-tab>

              <v-tab href="#tab-2" class="abas-item" @click="getUsuarios">
                Usuários
              </v-tab>
            </v-tabs>
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :key="1"
          :value="'tab-1'">
          <v-container>
            <v-skeleton-loader
              :loading="loadingSkeleton"
              :transition="'fade-transition'"
              height="350px"
              type="table">
                <v-data-table
                :headers="headers"
                :items="credits"
                flat
                dense
                class="table-extrato-creditos">

                <template v-slot:item.created="{ item }">
                  {{ formatDate(item.created) }}
                </template>

                <template v-slot:item.dtActivated="{ item }">
                  {{ formatDate(item.dtActivated) }}
                </template>

                <template v-slot:item.value="{ item }">
                  {{ item.value | currency }}
                </template>
                  
                </v-data-table>
            </v-skeleton-loader>
          </v-container>
        </v-tab-item>

        <v-tab-item
          :key="2"
          :value="'tab-2'">
          <v-container>
            <v-skeleton-loader
              :loading="loadingSkeleton"
              :transition="'fade-transition'"
              height="350px"
              type="table">
                <v-data-table
                :headers="headersEntities"
                :items="entities"
                flat
                dense
                class="table-extrato-creditos">

                  <template v-slot:item.jsonData.cpf="{ item }">
                    {{ item.jsonData.cpf | document }}
                  </template>

                  <template v-slot:item.jsonData.phone="{ item }">
                    {{ item.jsonData.phone | phone }}
                  </template>

                  
                </v-data-table>
            </v-skeleton-loader>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
import Events from '@/core/service/events'
import { formatDate } from '@/core/service/utils'

const namespaceStore = 'listBonifications'

export default {
  name: 'ModalMaisInformacoes',

  data: () => ({
    showModal: false,
    tab: 'tab-1',
    headers: [
      { align: 'start', class: 'table-header', text: 'Código', value: 'cod' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Data Compra', value: 'created' },
      { align: 'start', class: 'table-header', text: 'Data Ativação', value: 'dtActivated' }
    ],
    headersEntities: [
      { align: 'start', class: 'table-header', text: 'Nome', value: 'jsonData.fullName' },
      { align: 'start', class: 'table-header', text: 'CPF', value: 'jsonData.cpf' },
      { align: 'start', class: 'table-header', text: 'Telefone', value: 'jsonData.phone' },
    ],
    loadingSkeleton: false,
    idBonficacao: null
  }),

  watch: {
    'item.cod' (val) {
      this.item.cod = val.toUpperCase()
    }
  },

  mounted () {
    Events.$on('modalInformacoes::bonificacoes', id => {
      this.showModal = true
      this.loadingSkeleton = true
      this.idBonficacao = id
      this.getBonificationsCredits({ id })
        .finally(() => this.loadingSkeleton = false)
    })
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters(namespaceStore, ['credits', 'entities']),
    variables: () => variables,
  },


  methods: {
    ...mapActions(namespaceStore, ['getBonificationsCredits', 'getBonificationsEntities', 'clearItensModal']),
    closeModal () {
      this.showModal = false
      this.clearItensModal()
      this.tab = 'tab-1'
    },

    formatDate (date) {
      return formatDate(date)
    },

    getUsuarios () {
      this.loadingSkeleton = true
      this.getBonificationsEntities({ id: this.idBonficacao }).finally(() => this.loadingSkeleton = false)
    },

    getCredits () {
      this.loadingSkeleton = true
      this.getBonificationsCredits({ id: this.idBonficacao }).finally(() => this.loadingSkeleton = false)
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
</style>